import { useContext, useState, Suspense, lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import css from "./pesquisa.module.css";
import PesquisaRapida from "./PesquisaRapida/PesquisaRapida";
import { ReactComponent as PesquisaAnvanca } from "../../../../assets/icons/pesquisa/pesquisa_ava.svg";
import Spacer from "../Spacer";
import { useLocation } from "react-router";
import { useEffect } from "react";
import AuthContext from "../../../hooks/AuthContext";
import { getPesquisa } from "../../../../api/apiEmpresa";

const PesquisaAvancada = lazy(() =>
	import("./PesquisaAvançada/PesquisaAvancada")
);

export default function PesquisaBox({ setFilteredCarros, infoJson, home }) {
	const { state, dispatch } = useContext(AuthContext);

	const location = useLocation();
	const [avancada, setAvancada] = useState(
		infoJson.Pesquisa.avancada.principal
	);
	useEffect(() => {
		location.state && location.state.pesquisaAvancada && setAvancada(true);
	}, [location]);

	useEffect(() => {
		const getAllinfoText = async () => {
			const info = await getPesquisa(infoJson);

			setAvancada(info.avancada.principal);

			dispatch({ type: "AddPesquisa", list: info });
		};
		Object.keys(infoJson).length !== 0 && getAllinfoText();
	}, [infoJson, dispatch]);

	const clasSwift = (value) => {
		switch (value) {
			case 1:
				return `${css.colPesquisaPadding}`;
			case 2:
				return `${css.colPesquisaFloat}`;
			case 3:
				return `${css.colPesquisaFacilInline}`;
			case 4:
				return `${css.colPesquisaRapidoBox}`;
			case 5:
				return `${css.colPesquisaRapidoInline}`;
			case 6:
				return `${css.colPesquisaFloatBottom}`;

			default:
				return css.colPesquisaPadding;
		}
	};

	return (
		<>
			{!avancada && state.Pesquisa.rapida.tipo === 2 && home ? (
				<div style={{ display: state.Pesquisa.existe ? " block" : "none" }}>
					<Container>
						<div className={`${css.sectionFloat}`}>
							<h1
								className={`${css.tituloReduzido} ${
									home ? "titulo-Reduzido" : "titulo-Reduzido-interior"
								}  ps-4 mb-2 `}
							>
								Que Viatura Procura?
							</h1>
							<div
								className={` ${css.colPesquisaMenu} colPesquisaMenu ${clasSwift(
									state.Pesquisa.rapida.tipo
								)} `}
							>
								<PesquisaRapida
									setFilteredCarros={setFilteredCarros}
									infoJson={infoJson}
									avancada={avancada}
								/>
							</div>

							{!avancada && state.allCarros.length !== 0 && (
								<div
									onClick={() => {
										setAvancada(true);
									}}
									className={`${css.btn_avancada}  btn_avancada`}
								>
									<PesquisaAnvanca />
									Pesquisa Detalhada
								</div>
							)}
							<Suspense fallback={<></>}>
								<PesquisaAvancada
									setFilteredCarros={setFilteredCarros}
									infoJson={infoJson}
									setAvancada={setAvancada}
									avancada={avancada}
								/>
							</Suspense>
						</div>
					</Container>
				</div>
			) : (
				<section
					style={{ display: state.Pesquisa.existe ? " block" : "none" }}
					className={`${css.section}`}
				>
					<Container>
						<Row>
							<Col xs={12}>
								{state.Pesquisa.rapida.tipo === 1 ? (
									<h1 className={`${css.titulo} titulo-Pesquisa `}>
										{avancada ? <>Que Viatura Procura</> : <>Pesquisa Rápida</>}
									</h1>
								) : (
									<h1
										className={`${css.tituloReduzido} ${
											home ? "titulo-Reduzido" : "titulo-Reduzido-interior"
										} text-center mb-2`}
									>
										Que Viatura Procura?
									</h1>
								)}
							</Col>

							<Col
								xs={12}
								xl={{ span: 10, offset: 1 }}
								xxl={{ span: 8, offset: 2 }}
								className={` ${css.colPesquisaMenu} colPesquisaMenu ${
									!avancada
										? clasSwift(state.Pesquisa.rapida.tipo)
										: css.colPesquisaPaddingAvan
								} `}
							>
								<PesquisaRapida
									setFilteredCarros={setFilteredCarros}
									infoJson={infoJson}
									avancada={avancada}
								/>
								<Suspense fallback={<></>}>
									<PesquisaAvancada
										setFilteredCarros={setFilteredCarros}
										infoJson={infoJson}
										setAvancada={setAvancada}
										avancada={avancada}
									/>{" "}
								</Suspense>
							</Col>
						</Row>
						{state.Pesquisa.rapida.tipo === 4 && !avancada && (
							<>
								<Spacer
									clasName="d-block"
									height="20px"
								/>
							</>
						)}

						{!avancada && state.allLista.length !== 0 && (
							<div
								onClick={() => {
									setAvancada(true);
								}}
								className={`${css.btn_avancada}  btn_avancada`}
							>
								<PesquisaAnvanca />
								Pesquisa Detalhada
							</div>
						)}
					</Container>

					{avancada && (
						<>
							<Spacer
								clasName="d-block"
								height="30px"
							/>
							<Spacer
								clasName=" d-lg-none"
								height="30px"
							/>
						</>
					)}
				</section>
			)}
		</>
	);
}
