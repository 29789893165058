import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ImageRenderer from "../../../tools/images/ImageRenderer";
import { yScrollContext } from "../../../tools/restoreScroll/yScroll";
import css from "./recentes.module.css";
import imgNone from "../../../../assets/static_img/ind_media.webp";

export default function CardBullet({ data, tipo }) {
  const yScroll = useContext(yScrollContext);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");




  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });
      setImagem(
        ImagemPrincipal[0] === undefined
          ? {
              Ficheiro: imgNone,
              Indice: 6,
              Principal: true,
              Ordenador: "",
            }
          : ImagemPrincipal[0]
      );
    } else {
      setImagem({
        Ficheiro: imgNone,
        Indice: 6,
        Principal: true,
        Ordenador: "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (imagem?.Ficheiro) {
      const imagemSeloGrande = imagem?.Ficheiro;
      const imagemSeloMedio = imagem?.Ficheiro.replace("-6.", "-4.");

      const imageVariants = [
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  const changeClass = (value) => {
    switch (value) {
      case 1:
        return ` ${css.frame} `;

      case 2:
        return ` ${css.frameSquare} `;
      default:
        return ` ${css.frame} `;
    }
  };
  return (
    <Col xs={12}>
      <Link
        aria-label={`${data.textMarca} ${
          data.Viatura === "barcos" ? data.textMotorizacao : data.textModelo
        }`}
        to={data.location}
        state={{
          backward: {
            yscroll: yScroll,
          },
        }}
      >
        <Col xs={12}>
          {srcSet !== "" && (
            <ImageRenderer
              divName={`frame-recentes nopadding ${changeClass(tipo)}`}
              className={`${css.imagem}`}
              url={srcSet[1]?.srcSet}
              thumb={srcSet[0]?.srcSet}
              alt={`${data.textMarca} ${
                data.Viatura === "barcos"
                  ? data.textMotorizacao
                  : data.textModelo
              }`}
              sources={srcSet}
            />
          )}
        </Col>
        <Col className={` nopadding`}>
          <Col xs={12} className={css.marca}>
            {data.textMarca}
          </Col>
          <Col xs={12} className={css.modelo}>
            {data.Viatura === "barcos" ? data.textMotorizacao : data.textModelo}
          </Col>
        </Col>
      </Link>
    </Col>
  );
}
