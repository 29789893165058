import css from "./siteholder.module.css";
import { ApiLinkImagens } from "../../../config/config";

export default function Siteholder({ infoJson }) {
  return (
    <h1
      className={`${css.box} ${
        infoJson.Layout.ThemeDark ? css.black : css.white
      }`}
    >
      <img
        src={`${ApiLinkImagens}/${
          infoJson?.Layout?.Siteholder?.Logo?.url
            ? infoJson?.Layout?.Siteholder?.Logo?.url
            : "logo"
        }`}
        className={`img-Siteholder`}
        alt={infoJson.Stand.Nome}
      />
    </h1>
  );
}
