import { useEffect } from "react";
import OneSignal from "react-onesignal";

const useOneSignal = (plugin) => {
  useEffect(() => {
    if (plugin?.OneSignal?.existe && plugin?.OneSignal?.appId) {
      OneSignal.init({
        appId: plugin.OneSignal.appId,
      });
      OneSignal.showSlidedownPrompt();
    }
  }, [plugin?.OneSignal?.existe, plugin?.OneSignal?.appId]);
};

export default useOneSignal;
