import axios from "axios";

const global = require("../config/config");

async function getInfoTextos(infoJson) {
  try {
    const API_URL_TEXTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetInfoTextos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_TEXTOS);
    const apiTextos = res.data || {};

    const mergedTextos = Object.keys(apiTextos).reduce((acc, key) => {
      acc[key] = {
        Titulo:
          apiTextos[key]?.Titulo?.trim() || infoJson[key]?.titulo?.trim() || "",
        SubTitulo:
          apiTextos[key]?.SubTitulo?.trim() ||
          infoJson[key]?.subTitulo?.trim() ||
          "",
        Zona: apiTextos[key]?.Zona?.trim() || infoJson[key]?.zona?.trim() || "",
        Url: apiTextos[key]?.Url?.trim() || infoJson[key]?.url?.trim() || "",
        MetaTitle:
          apiTextos[key]?.MetaTitle?.trim() ||
          infoJson[key]?.metaTitle?.trim() ||
          "",
        Banner:
          apiTextos[key]?.Banner && apiTextos[key].Banner !== 0
            ? apiTextos[key].Banner
            : infoJson[key]?.banner || infoJson[key]?.Pagina?.codBanner || "",
        Layout: infoJson[key]?.Pagina?.Layout || "Full-width",
        Video: infoJson[key]?.Video?.Src || infoJson[key]?.Pagina?.Video || "",
        CodEquipa: infoJson[key]?.Pagina?.codEquipa || "",
        ListagemNumero: infoJson[key]?.Pagina?.listagemNumero || "",
        TipoPagina: infoJson[key]?.Pagina?.tipo || "",
      };

      return acc;
    }, {});

    // Garante que o Banner da Empresa é corretamente tratado
    mergedTextos.Empresa = {
      ...mergedTextos.Empresa,
      Banner:
        apiTextos.Empresa?.Banner && apiTextos.Empresa.Banner !== 0
          ? apiTextos.Empresa.Banner
          : infoJson.Banner?.Empresa || "",
    };

    // Adiciona um único objeto "Banner" com Mobile, Desktop e Empresa ao resultado final
    mergedTextos.Banner = {
      Mobile: infoJson.Banner?.Mobile,
      Desktop: infoJson.Banner?.Desktop,
    };

    return mergedTextos;
  } catch (error) {
    console.error(
      "Erro ao buscar os textos:",
      error.response?.data || error.message
    );
    return infoJson; // Se houver erro, retorna os dados locais
  }
}

async function getInfoPopUp(infoJson) {
  try {
    const API_URL_TEXTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetPopUp/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.log("error", error);
  }
}

async function getInfoEmpresa(infoJson) {
  try {
    var API_URL_INFO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetContactosClienteAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_INFO);
    const info = res.data;
    return info;
  } catch (error) {
    console.log("error", error);
  }
}

async function getBanner(codBanner, infoJson) {
  try {
    const API_URL_BANNERS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetBanner/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        zona_id: codBanner,
        numero: infoJson.Banner.Top,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_BANNERS);

    if (res.data.length > 0) {
      const banners = res.data;
      return banners;
    }
    return [];
  } catch (error) {
    console.log("error", error);
  }
}

async function getServicoOficina(infoJson) {
  try {
    const API_URL_SERVICOS_OFICINA = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetServicoOficina/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_SERVICOS_OFICINA);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getHorarios(CodPontoVenda, Concelho, infoJson) {
  try {
    const API_URl_HORARIOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetHorario/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        stand_id: CodPontoVenda,
        country: Concelho,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_HORARIOS);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getIntervaloPreco(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetIntervaloPreco/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
async function getIntervaloMensalidade(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetIntervaloMensalidade/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPrivacidade(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/privacidade/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getCookies(infoJson, nomeEmpresa, email) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/cookies/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        nomeEmpresa,
        email,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPlugins(infoJson) {
  try {
    const API_URL_PLUGINS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easysite/GetPlugins/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };
    // dados da api
    const res = await axios.request(API_URL_PLUGINS);
    const data = res.data[0]; // Garantir que acessamos o objeto correto

    const plugins = {
      PluginFacebook: {
        existe: data.FacebookAtivo,
        pixelId: data.FacebookId || "",
        facebookDomainVerification: data.FacebookDomainVerification || "",
      },
      WidgetWhats: {
        existe: data.WhatsappAtivo,
        dataWwa: data.WhatsappId || "",
      },
      OneSignal: {
        existe: data.OneSignalAtivo,
        appId: data.OneSignalId || "",
      },
      WidgeAvaly: {
        existe: data.AvalyAtivo,
        id: data.AvalyId || "",
      },
      Lead2team: {
        existe: data.Lead2TeamAtivo,
        apiKey: data.Lead2TeamId || "",
      },
      GoogleAnalytics: {
        existe: data.GoogleAtivo,
        measurementIds: [data.GoogleAnalytics, data.GoogleTagsManager],
      },
      AutoUncle: {
        existe: data.AutoUncleAtivo,
        config: {
          id: data.AutoUncleId || "",
        },
      },
      Tiktok: {
        existe: data.TiktokAtivo,
        id: data.TiktokId || "",
      },
      OneTrust: {
        existe: data.OneTrustAtivo,
        id: data.OneTrustId || "",
      },
    };

    // infojson

    return {
      PluginFacebook: {
        ...(plugins.PluginFacebook.existe
          ? plugins.PluginFacebook
          : infoJson.Stand.PluginFacebok?.existe
          ? infoJson.Stand.PluginFacebok
          : plugins.PluginFacebook),
      },
      WidgetWhats: {
        ...(plugins.WidgetWhats.existe
          ? plugins.WidgetWhats
          : infoJson.Stand.WidgetWhats?.existe
          ? infoJson.Stand.WidgetWhats
          : plugins.WidgetWhats),
      },
      OneSignal: {
        ...(plugins.OneSignal.existe
          ? plugins.OneSignal
          : infoJson.Stand.OneSignal?.existe
          ? infoJson.Stand.OneSignal
          : plugins.OneSignal),
      },
      WidgeAvaly: {
        ...(plugins.WidgeAvaly.existe
          ? plugins.WidgeAvaly
          : infoJson.Stand.WidgeAvaly?.existe
          ? infoJson.Stand.WidgeAvaly
          : plugins.WidgeAvaly),
      },
      Lead2team: {
        ...(plugins.Lead2team.existe
          ? plugins.Lead2team
          : infoJson.Stand.Lead2team?.existe
          ? infoJson.Stand.Lead2team
          : plugins.Lead2team),
      },
      GoogleAnalytics: {
        ...(plugins.GoogleAnalytics.existe
          ? plugins.GoogleAnalytics
          : infoJson.Stand.GoogleAnalytics?.existe
          ? infoJson.Stand.GoogleAnalytics
          : plugins.GoogleAnalytics),
      },
      AutoUncle: {
        ...(plugins.AutoUncle.existe
          ? plugins.AutoUncle
          : infoJson.Stand.AutoUncle?.existe
          ? infoJson.Stand.AutoUncle
          : plugins.AutoUncle),
      },
      Tiktok: {
        ...(plugins.Tiktok.existe
          ? plugins.Tiktok
          : infoJson.Stand.Tiktok?.existe
          ? infoJson.Stand.Tiktok
          : plugins.Tiktok),
      },
      OneTrust: {
        ...(plugins.OneTrust.existe
          ? plugins.OneTrust
          : infoJson.Stand.OneTrust?.existe
          ? infoJson.Stand.OneTrust
          : plugins.OneTrust),
      },
    };
  } catch (error) {
    console.error(
      "Error fetching plugins:",
      error.response?.data || error.message
    );

    return infoJson.Stand;
  }
}

async function getPesquisa(infoJson) {
  try {
    const API_URL_PESQUISA = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easysite/GetPesquisa/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_PESQUISA);
    const data = res.data;

    const pesquisa =
      data && Object.keys(data).length > 0 ? data : infoJson.Pesquisa;

    // Mapeamento do tipo de pesquisa
    const tipoPesquisaMap = {
      pesquisa_texto: 1,
      pesquisa_dois_campos: 5,
      pesquisa_tres_campos_linha: 3,
      pesquisa_tres_campos_bloco: 6,
      sobreposta_banner: 2,
      pesquisa_detalhada: 1,
      sem_pesquisa: 1,
    };

    let resultado = "";

    resultado = {
      existe: pesquisa.tipoPesquisa.tipo_pesquisa !== "sem_pesquisa",
      rapida: {
        tipo:
          tipoPesquisaMap[pesquisa.tipoPesquisa.tipo_pesquisa] ||
          infoJson.Pesquisa.rapida.tipo,
      },
      avancada: {
        principal: pesquisa.tipoPesquisa.tipo_pesquisa === "pesquisa_detalhada",
      },
    };

    return resultado;
  } catch (error) {
    console.error(
      "Error fetching pesquisa:",
      error.response?.data || error.message
    );
    return infoJson.Pesquisa;
  }
}

async function getUltimasEntradas(infoJson) {
  try {
    const API_URL_UltimasEntradas = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easysite/GetUltimasEntradas/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_UltimasEntradas);
    const apiUltimasEntradas = res.data;

    const valoresValidos = {
      circulo: 1,
      rectangulo: 3,
    };

    let existe = infoJson.Layout?.UltimasEntradas?.existe;

    if (apiUltimasEntradas?.TipoEntrada) {
      if (apiUltimasEntradas?.TipoEntrada === "inativo") {
        existe = false;
      } else if (valoresValidos[apiUltimasEntradas?.TipoEntrada]) {
        existe = true;
      }
    }

    const UltimasEntradas = {
      ...infoJson.Layout.UltimasEntradas,
      existe: existe,
      tipo: apiUltimasEntradas?.TipoEntrada
        ? valoresValidos[apiUltimasEntradas.TipoEntrada]
        : infoJson.Layout?.UltimasEntradas?.tipo,
      ordenacao:
        apiUltimasEntradas?.TipoOrdenacao?.trim() ??
        infoJson.Layout?.UltimasEntradas?.ordenacao ??
        "DataCriacao",
      top:
        apiUltimasEntradas?.NumeroCarros ??
        infoJson.Layout?.UltimasEntradas?.top ??
        10,
    };

    return UltimasEntradas;
  } catch (error) {
    console.error("Error fetching Ultimas Entradas:", error.message);

    return {
      ...infoJson.Layout.UltimasEntradas,
      existe: infoJson.Layout?.UltimasEntradas?.existe ?? true, // Mantém o valor existente ou usa true como padrão
      tipo: infoJson.Layout?.UltimasEntradas?.tipo ?? null,
      ordenacao: infoJson.Layout?.UltimasEntradas?.ordenacao ?? "DataCriacao",
      top: infoJson.Layout?.UltimasEntradas?.top ?? 10,
    };
  }
}

async function getDestaque(infoJson) {
  try {
    const API_URL_DESTAQUE = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easysite/GetDestaque/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_DESTAQUE);
    const data = res.data;

    if (data && Object.keys(data).length > 0) {
      let resultado = "";

      resultado = {
        destaquesBanner: {
          existe: data.tipoDestaque.tipo_destaque === 3,
          TipoDestaques:
            infoJson.Layout.Destaques.destaquesBanner.TipoDestaques,
        },
        existe:
          data.tipoDestaque.tipo_destaque !== 6 &&
          data.tipoDestaque.tipo_destaque !== 3,
        aparecerVendidos: infoJson.Layout.Destaques.aparecerVendidos,
        naoAparecerBtnToStock: infoJson.Layout.Destaques.naoAparecerBtnToStock,
        tipo: data.tipoDestaque.tipo_destaque,
      };

      return resultado;
    } else {
      return infoJson.Layout.Destaques;
    }
  } catch (error) {
    console.error(
      "Error fetching destaque:",
      error.response?.data || error.message
    );
    return infoJson.Layout.Destaque;
  }
}

export {
  getInfoEmpresa,
  getPlugins,
  getPesquisa,
  getDestaque,
  getBanner,
  getServicoOficina,
  getHorarios,
  getIntervaloPreco,
  getIntervaloMensalidade,
  getInfoTextos,
  getPrivacidade,
  getInfoPopUp,
  getCookies,
  getUltimasEntradas,
};
