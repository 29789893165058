import { ReactComponent as Autocaravanas } from "../assets/icons/tipo/autocaravanas.svg";
import { ReactComponent as Tractor } from "../assets/icons/tipo/tractor.svg";
import { ReactComponent as MobileHomes } from "../assets/icons/tipo/mob.svg";
import { ReactComponent as Comercial } from "../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../assets/icons/tipo/motos.svg";
import { ReactComponent as Barcos } from "../assets/icons/tipo/tipo_barco.svg";
import { ReactComponent as Classico } from "../assets/icons/tipo/class.svg";
import { ReactComponent as Vendido } from "../assets/icons/tipo/vendido.svg";
import { ReactComponent as Lowcost } from "../assets/icons/tipo/lowcost.svg";
import { ReactComponent as Retoma } from "../assets/icons/tipo/retoma.svg";
import { ReactComponent as Atrelado } from "../assets/icons/tipo/atrelado.svg";
import { ReactComponent as Pecas } from "../assets/icons/tipo/pecas.svg";
import { ReactComponent as Motores } from "../assets/icons/tipo/motores.svg";
import { ReactComponent as Novos } from "../assets/icons/tipo/novos.svg";

export const combEletricos = [41, 39, 57, 58, 60, 61];
export const carrosComerciais = [
  46, 86, 59, 60, 61, 64, 65, 66, 87, 88, 89, 114, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 111, 112, 113, 117, 119,
];
export const carrosAutocaravanas = [68, 91];
export const mobileHomes = [123];
export const tractor = [110];

//tipo mota e barco
export const motas = [90];
export const barcos = [93];

//estado mota nova
export const motasNova = [40];

export const CaixaManual = [33, 34, 35, 40, 42];
export const CaixaAutomatica = [32, 36, 37, 38, 39, 41, 43, 44];

export const viaturasConfig = {
  automoveis: {
    filter: (array) => array.filter((carro) => true),
    Dispatch: "AddCarros",
    svg: Berlina,
    state: "allCarros",
    titulo: "Automóveis",
    ordenar: 1,
    existe: true,
  },
  comerciais: {
    filter: (array, matched, { vendidoExiste, classicosExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "carros" &&
            carrosComerciais.includes(carro.Tipo.Id) &&
            (classicosExiste ? carro.Estado.Id !== 41 : true) &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "carros" &&
              carrosComerciais.includes(carro.Tipo.Id) &&
              (classicosExiste ? carro.Estado.Id !== 41 : true) &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddComercias",
    svg: Comercial,
    state: "allComercias",
    titulo: "Comerciais",
    ordenar: 2,
    existe: true,
  },
  classicos: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Estado.Id === 41 &&
            carro.Viatura === "carros" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Estado.Id === 41 &&
              carro.Viatura === "carros" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddClassicos",
    svg: Classico,
    state: "allClassicos",
    titulo: "Clássicos",
    ordenar: 3,
    existe: true,
  },
  motas: {
    filter: (array, matched, { vendidoExiste, motasConfig }) => {
      const { motasNovasExiste } = motasConfig;

      return array.filter((carro) => {
        const result = matched
          ? (carro.Viatura === "motas" &&
              (vendidoExiste ? carro.Vendido !== true : true) &&
              (motasNovasExiste
                ? !motasNova.includes(carro.Estado.Id)
                : true)) ||
            (carro.Viatura === "carros" &&
              motas.includes(carro.Tipo.Id) &&
              (vendidoExiste ? carro.Vendido !== true : true) &&
              (motasNovasExiste ? !motasNova.includes(carro.Estado.Id) : true))
          : !(
              (carro.Viatura === "motas" &&
                (vendidoExiste ? carro.Vendido !== true : true) &&
                (motasNovasExiste
                  ? !motasNova.includes(carro.Estado.Id)
                  : true)) ||
              (carro.Viatura === "carros" &&
                motas.includes(carro.Tipo.Id) &&
                (vendidoExiste ? carro.Vendido !== true : true) &&
                (motasNovasExiste
                  ? !motasNova.includes(carro.Estado.Id)
                  : true))
            );

        return result;
      });
    },
    Dispatch: "AddMotas",
    svg: Motos,
    state: "allMotas",
    titulo: "Motos",
    ordenar: 4,
    existe: true,
  },
  "motas-novas": {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? (carro.Viatura === "motas" &&
              (vendidoExiste ? carro.Vendido !== true : true) &&
              motasNova.includes(carro.Estado.Id)) ||
            (carro.Viatura === "carros" &&
              motas.includes(carro.Tipo.Id) &&
              (vendidoExiste ? carro.Vendido !== true : true) &&
              motasNova.includes(carro.Estado.Id))
          : !(
              (carro.Viatura === "motas" &&
                (vendidoExiste ? carro.Vendido !== true : true) &&
                motasNova.includes(carro.Estado.Id)) ||
              (carro.Viatura === "carros" &&
                motas.includes(carro.Tipo.Id) &&
                (vendidoExiste ? carro.Vendido !== true : true) &&
                motasNova.includes(carro.Estado.Id))
            )
      ),
    Dispatch: "AddMotasNovas",
    svg: Motos,
    state: "allMotasNovas",
    titulo: "Motos Novas",
    ordenar: 4,
    existe: true,
  },
  barcos: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? (carro.Viatura === "barcos" &&
              (vendidoExiste ? carro.Vendido !== true : true)) ||
            (carro.Viatura === "carros" &&
              barcos.includes(carro.Tipo.Id) &&
              (vendidoExiste ? carro.Vendido !== true : true))
          : !(
              (carro.Viatura === "barcos" &&
                (vendidoExiste ? carro.Vendido !== true : true)) ||
              (carro.Viatura === "carros" &&
                barcos.includes(carro.Tipo.Id) &&
                (vendidoExiste ? carro.Vendido !== true : true))
            )
      ),
    Dispatch: "AddBarcos",
    svg: Barcos,
    state: "allBarcos",
    titulo: "Barcos",
    ordenar: 5,
    existe: true,
  },
  pecas: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "pecas" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "pecas" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddPecas",
    svg: Pecas,
    state: "allPecas",
    titulo: "Peças",
    ordenar: 5,
    existe: true,
  },
  atrelados: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "atrelados" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "atrelados" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddAtrelados",
    svg: Atrelado,
    state: "allAtrelados",
    titulo: "Atrelados",
    ordenar: 5,
    existe: true,
  },
  motores: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "motores" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "motores" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddMotores",
    svg: Motores,
    state: "allMotores",
    titulo: "Motores",
    ordenar: 5,
    existe: true,
  },
  autocaravanas: {
    filter: (array, matched, { vendidoExiste, classicosExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "carros" &&
            carrosAutocaravanas.includes(carro.Tipo.Id) &&
            (classicosExiste ? carro.Estado.Id !== 41 : true) &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "carros" &&
              carrosAutocaravanas.includes(carro.Tipo.Id) &&
              (classicosExiste ? carro.Estado.Id !== 41 : true) &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddAutocaravanas",
    svg: Autocaravanas,
    state: "allAutocaravanas",
    titulo: "Autocaravanas",
    ordenar: 6,
    existe: true,
  },
  "mobile-home": {
    filter: (array, matched, { vendidoExiste, classicosExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "carros" &&
            mobileHomes.includes(carro.Tipo.Id) &&
            (classicosExiste ? carro.Estado.Id !== 41 : true) &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "carros" &&
              mobileHomes.includes(carro.Tipo.Id) &&
              (classicosExiste ? carro.Estado.Id !== 41 : true) &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddMobileHomes",
    svg: MobileHomes,
    state: "mobileHomes",
    titulo: "Mobile Home",
    ordenar: 7,
    existe: true,
  },
  tractor: {
    filter: (array, matched, { vendidoExiste, classicosExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Viatura === "carros" &&
            tractor.includes(carro.Tipo.Id) &&
            (classicosExiste ? carro.Estado.Id !== 41 : true) &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Viatura === "carros" &&
              tractor.includes(carro.Tipo.Id) &&
              (classicosExiste ? carro.Estado.Id !== 41 : true) &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddTractor",
    svg: Tractor,
    state: "allTractor",
    titulo: "Tractor",
    ordenar: 8,
    existe: true,
  },
  "low-cost": {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Estado.Id === 43 &&
            carro.Viatura === "carros" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Estado.Id === 43 &&
              carro.Viatura === "carros" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddLowcost",
    svg: Lowcost,
    state: "allLowcost",
    titulo: "Low-Cost",
    ordenar: 9,
    existe: false,
  },
  retomas: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Estado.Id === 37 &&
            carro.Viatura === "carros" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Estado.Id === 37 &&
              carro.Viatura === "carros" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddRetoma",
    svg: Retoma,
    state: "allRetoma",
    titulo: "Retoma",
    ordenar: 10,
    existe: false,
  },
  novos: {
    filter: (array, matched, { vendidoExiste }) =>
      array.filter((carro) =>
        matched
          ? carro.Estado.Id === 40 &&
            carro.Viatura === "carros" &&
            (vendidoExiste ? carro.Vendido !== true : true)
          : !(
              carro.Estado.Id === 40 &&
              carro.Viatura === "carros" &&
              (vendidoExiste ? carro.Vendido !== true : true)
            )
      ),
    Dispatch: "AddNovos",
    svg: Novos,
    state: "allNovos",
    titulo: "Novos",
    ordenar: 11,
    existe: false,
  },
  vendidos: {
    filter: (array, matched) =>
      array.filter((carro) =>
        matched ? carro.Vendido === true : !(carro.Vendido === true)
      ),
    Dispatch: "AddVendidos",
    svg: Vendido,
    state: "allVendidos",
    titulo: "Vendidos",
    ordenar: 12,
    existe: false,
  },
};
