import { useEffect, useState } from "react";
import * as apiEmpresa from "../../../../api/apiEmpresa";
import css from "./banners.module.css";
import Carousel from "react-bootstrap/Carousel";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import ImageLoad from "../../../hooks/ImageLoad";
import { ApiLinkImagens } from "../../../../config/config";
import Spacer from "../Spacer";

export default function Banners({ infoJson, allinfoText }) {
  const [BannerDesktop, setBannerDesktop] = useState([]);
  const [BannerMobile, setBannerMobile] = useState([]);
  const [Banner, setBanner] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery("(max-width: 991px)");

  useEffect(() => {
    const getBanner = async () => {
      try {
        const bannersDesktop = await apiEmpresa.getBanner(
          allinfoText.Banner.Desktop,
          infoJson
        );

        setBannerDesktop(bannersDesktop);
        const bannersMobile = await apiEmpresa.getBanner(
          allinfoText.Banner.Mobile,
          infoJson
        );
        setBannerMobile(bannersMobile);
        isMobile ? setBanner(bannersMobile) : setBanner(bannersDesktop);
      } catch (error) {
        console.error("Error fetching Noticias:", error);
        // Handle error if needed
      } finally {
        setLoading(false); // Set loading to false after the request is completed
      }
    };
    getBanner();
  }, [isMobile, infoJson, allinfoText]);

  useEffect(() => {
    isMobile ? setBanner(BannerMobile) : setBanner(BannerDesktop);
  }, [isMobile, BannerMobile, BannerDesktop]);

  useEffect(() => {
    const items = [];
    for (let index = 1; index <= infoJson.Banner.Numero; index++) {
      let imagem_selo;
      isMobile
        ? (imagem_selo = `${`${ApiLinkImagens}/header_mobile${index}`}`)
        : (imagem_selo = `${`${ApiLinkImagens}/header_desktop${index}`}`);
      items.push(
        <Carousel.Item key={index}>
          <div className={`${css.banner}`}>
            {imagem_selo && (
              <ImageLoad src={imagem_selo} alt={`banner${index}`} />
            )}
          </div>
        </Carousel.Item>
      );
    }
    setRows(items);
  }, [isMobile, infoJson]);

  return (
    <>
      {!infoJson.Layout.Transparente.existe && <Spacer height="85px" />}

      <Carousel fade={true} controls={false} interval={3000} indicators={false}>
        {loading ? (
          <Carousel.Item></Carousel.Item>
        ) : Banner.length !== 0 &&
          Banner.length !== undefined &&
          Banner.length !== null ? (
          Banner.map((banner, index) => {
            const { Ficheiro, Target, Url, CodBanner } = banner;

            return (
              <Carousel.Item key={CodBanner}>
                <div className={`${css.banner}`}>
                  {Url !== "" && Target !== "" ? (
                    <a
                      href={Url}
                      target={Target}
                      rel="noreferrer"
                      aria-label={`banner${index}`}
                    >
                      {Ficheiro && (
                        <ImageLoad src={Ficheiro} alt={`banner${index}`} />
                      )}
                    </a>
                  ) : (
                    Ficheiro && (
                      <ImageLoad src={Ficheiro} alt={`banner${index}`} />
                    )
                  )}
                </div>
              </Carousel.Item>
            );
          })
        ) : (
          rows
        )}
      </Carousel>
    </>
  );
}
