import { Suspense, lazy, useContext } from "react";

import AuthContext from "../../../../hooks/AuthContext";

const FormPesquisaRapida = lazy(() => import("./FormPesquisaRapida"));
const FormPesquisaReduzida = lazy(() => import("./FormPesquisaReduzida"));

export default function PesquisaRapida({
  setFilteredCarros,
  infoJson,
  avancada,
}) {
  const { state } = useContext(AuthContext);

  const selecionaPesquisaRapida = (tipo) => {
    if (tipo === 1) {
      return (
        <Suspense fallback={<></>}>
          <FormPesquisaRapida
            Array={state.allLista}
            setFilteredCarros={setFilteredCarros}
            tipo="/"
            infoJson={infoJson}
            avancada={avancada}
          />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<></>}>
          <FormPesquisaReduzida
            Array={state.allLista}
            tipo="/"
            infoJson={infoJson}
            avancada={avancada}
          />
        </Suspense>
      );
    }
  };

  return <> {selecionaPesquisaRapida(state.Pesquisa.rapida.tipo)}</>;
}
