import { hostname_data } from "../components/helpers/tratarInfo";

export const ApiLink = "https://ws.easydata.pt";
// export const ApiLink = "https://prews.easydata.pt";
// export const ApiLink = "https://prewsold.easydata.pt";
// export const ApiLink = "http://192.168.1.229:8090";
//export const ApiLink = "http://127.0.0.1:8090";

//export const ApiLinkMultidealer = "http://192.168.0.153:8000";
export const ApiLinkMultidealer = "https://multidealer.easysite.pt";
// export const ApiLinkMultidealer = "http://multidealer.test";

export const ApiLinkImagens = `${ApiLinkMultidealer}/assets/img/${hostname_data()}`;
/* Keys FileStack*/

export const PickerOptions = {
	maxSize: 10 * 1024 * 1024,
	maxFiles: 12,
	fromSources: ["local_file_system"],
	lang: "pt",
	accept: [
		"image/jpeg",
		"image/jpg",
		"image/png",
		"image/bmp",
		"image/gif",
		"application/pdf",
	],
};
/* Dados */
