import { createContext, useReducer } from "react";

// declare AuthContext as a Context object
export const AuthContext = createContext();

const initialState = {
	allLista: [],
	allCarros: [],
	allComercias: [],
	allClassicos: [],
	allAutocaravanas: [],
	mobileHomes: [],
	allTractor: [],
	allLowcost: [],
	allRetoma: [],
	allNovos: [],
	allMotasNovas: [],
	allMotas: [],
	allBarcos: [],
	allPecas: [],
	allMotores: [],
	allAtrelados: [],
	allVendidos: [],
	codPontoVenda: 0,
	allinfo: [],
	infoPrincipal: [],
	ArrayDestaques: [],
	home: false,
	plugins: {},
	Pesquisa: "",
	ultimasEntradas: [],
	Destaques: "",
};

// reducer to manage auth state
const reducer = (prevState, action) => {
	switch (action.type) {
		case "AddLista":
			return {
				...prevState,
				allLista: action.list,
			};
		case "AddCarros":
			return {
				...prevState,
				allCarros: action.list,
			};
		case "AddComercias":
			return {
				...prevState,
				allComercias: action.list,
			};
		case "AddClassicos":
			return {
				...prevState,
				allClassicos: action.list,
			};
		case "AddAutocaravanas":
			return {
				...prevState,
				allAutocaravanas: action.list,
			};
		case "AddMobileHomes":
			return {
				...prevState,
				mobileHomes: action.list,
			};

		case "AddTractor":
			return {
				...prevState,
				allTractor: action.list,
			};
		case "AddVendidos":
			return {
				...prevState,
				allVendidos: action.list,
			};
		case "AddLowcost":
			return {
				...prevState,
				allLowcost: action.list,
			};
		case "AddRetoma":
			return {
				...prevState,
				allRetoma: action.list,
			};
		case "AddNovos":
			return {
				...prevState,
				allNovos: action.list,
			};
		case "AddMotas":
			return {
				...prevState,
				allMotas: action.list,
			};
		case "AddMotasNovas":
			return {
				...prevState,
				allMotasNovas: action.list,
			};
		case "AddBarcos":
			return {
				...prevState,
				allBarcos: action.list,
			};
		case "AddPecas":
			return {
				...prevState,
				allPecas: action.list,
			};
		case "AddMotores":
			return {
				...prevState,
				allMotores: action.list,
			};
		case "AddAtrelados":
			return {
				...prevState,
				allAtrelados: action.list,
			};
		case "AddPontoVenda":
			return {
				...prevState,
				codPontoVenda: action.value,
			};
		case "AddInfo":
			return {
				...prevState,
				allinfo: action.list,
			};
		case "AddPrincipal":
			return {
				...prevState,
				infoPrincipal: action.list,
			};

		case "ExistHome":
			return {
				...prevState,
				home: action.list,
			};
		case "AddArrayDestaques":
			return {
				...prevState,
				ArrayDestaques: action.list,
			};

		case "AddPlugins":
			return {
				...prevState,
				plugins: action.list,
			};

		case "AddPesquisa":
			return {
				...prevState,
				Pesquisa: action.list,
			};
		case "AddUltimasEntradas":
			return {
				...prevState,
				ultimasEntradas: action.list,
			};
		case "AddDestaque":
			return {
				...prevState,
				Destaques: action.list,
			};
		default:
			return initialState;
	}
};

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<AuthContext.Provider value={{ state, dispatch }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
