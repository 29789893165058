import { lazy, Suspense, useContext, useEffect, useState } from "react";
import Rotas from "./components/routes/Rotas";
import * as apiCarros from "./api/apiCarros";
import * as apiMotas from "./api/apiMotas";
import * as apiBarcos from "./api/apiBarcos";
import * as apiEmpresa from "./api/apiEmpresa";
import * as apiPecas from "./api/apiPecas";
import * as apiJson from "./api/apiJson";

import useStateWithLocalStorage from "./components/hooks/useStateWithLocalStorage ";
import useMediaQuery from "./components/hooks/useMediaQuery ";
import Siteholder from "./components/tools/placeholder/Siteholder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import ReactSEOMetaTags from "react-seo-meta-tags";
import { lightTheme, darkTheme } from "./config/theme";
import GlobalTheme from "./config/global";

import AuthContext from "./components/hooks/AuthContext";
import WidgetProvider from "./components/hooks/WidgetProvider";
import { selectSegmentos } from "./components/helpers/tratarServicos";
import GestorNeves from "./components/tools/snowFall/GestorNeves";

const CookieConsentir = lazy(() =>
  import("./components/tools/cookies/CookieConsentir")
);

export default function App() {
  //  console.log("website + location.pathname", website + location.pathname);

  const { state, dispatch } = useContext(AuthContext);
  const [infoJson, setInfoJson] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [selectedCarsComparar, setSelectedCarsComparar] =
    useStateWithLocalStorage("myValueInLocalStorage");
  const [showModalComparison, setShowModalComparison] = useState(false);
  const [textoModal, setTextoModal] = useState(false);
  const [allinfoText, setAllinfoText] = useState([]);

  useEffect(() => {
    const getJson = async () => {
      const info = await apiJson.infoJson();
      setInfoJson(info);
    };

    getJson();
  }, []);

  useEffect(() => {
    const getAllinfoText = async () => {
      const info = await apiEmpresa.getInfoTextos(infoJson);

      setAllinfoText(info);
    };
    Object.keys(infoJson).length !== 0 && getAllinfoText();
  }, [infoJson]);

  useEffect(() => {
    const getViaturas = async () => {
      const viaturas = await apiCarros.getListaViaturas(infoJson);
      let motas = [];
      let barcos = [];
      let pecas = [];

      if (infoJson.Stand.Easymanager) {
        motas = [];
        barcos = [];
        pecas = [];
      } else {
        motas = await apiMotas.getListaMotas(infoJson);
        barcos = await apiBarcos.getListaBarcos(infoJson);
        pecas = await apiPecas.getListaPecas(infoJson);
      }

      const vJuntas = viaturas
        .concat(motas)
        .concat(barcos)
        .concat(pecas)
        .sort(
          (a, b) => new Date(b.UltimaAlteracao) - new Date(a.UltimaAlteracao)
        );

      selectSegmentos(infoJson, vJuntas, dispatch);

      dispatch({ type: "AddLista", list: vJuntas });
    };
    if (Object.keys(infoJson).length !== 0) {
      getViaturas();
    }
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getEmpresas = async () => {
      const info = await apiEmpresa.getInfoEmpresa(infoJson);

      dispatch({ type: "AddInfo", list: info });

      const infoPrincipal = info.filter((empresa) => {
        return empresa.Principal === true;
      });

      dispatch({ type: "AddPrincipal", list: infoPrincipal });
    };

    Object.keys(infoJson).length !== 0 && getEmpresas();
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getPlugins = async () => {
      const plugin = await apiEmpresa.getPlugins(infoJson);

      dispatch({ type: "AddPlugins", list: plugin });
    };

    Object.keys(infoJson).length !== 0 && getPlugins();
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getPesquisa = async () => {
      const pesquisa = await apiEmpresa.getPesquisa(infoJson);

      dispatch({ type: "AddPesquisa", list: pesquisa });
    };

    Object.keys(infoJson).length !== 0 && getPesquisa();
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getDestaque = async () => {
      const destaque = await apiEmpresa.getDestaque(infoJson);

      dispatch({ type: "AddDestaque", list: destaque });
    };

    Object.keys(infoJson).length !== 0 && getDestaque();
  }, [infoJson, dispatch]);

  useEffect(() => {
    const fetchUltimasEntradas = async () => {
      const ultimasEntradas = await apiEmpresa.getUltimasEntradas(infoJson);


      dispatch({ type: "AddUltimasEntradas", list: ultimasEntradas });
    };

    if (Object.keys(infoJson).length !== 0) {
      fetchUltimasEntradas();
    }
  }, [infoJson, dispatch]);

  const isDesktop = useMediaQuery("(min-width: 992px)");
  const addToCompare = (item) => {
    let numLimit;
    !isDesktop ? (numLimit = 2) : (numLimit = 3);
    if (
      selectedCarsComparar.length >= 1 &&
      item.Viatura !== selectedCarsComparar[0].Viatura
    ) {
      setShowModalComparison(true);
      setTextoModal(
        " O viatura que tentaste adicionar não é compatível com os restantes viaturas no comparador."
      );
      return;
    }
    if (selectedCarsComparar.length >= numLimit) {
      setShowModalComparison(true);
      setTextoModal(
        " O limite de viaturas no comparador foi atingido. Por favor, remove uma viatura para poderes adicionar mais."
      );
    } else {
      setSelectedCarsComparar((selectedCarsComparar) => [
        ...selectedCarsComparar,
        item,
      ]);
    }
  };

  const removeFromCompare = (item) => {
    const filteredItems = selectedCarsComparar.filter(
      (product) => product.CodViatura !== item.CodViatura
    );
    setSelectedCarsComparar((selectedCarsComparar) => filteredItems);
  };
  const removeFromCompareAll = () => {
    setSelectedCarsComparar([]);
  };

  return (
    <>
      {Object.keys(infoJson).length !== 0 &&
        Object.keys(process.envx).length !== 0 &&
        Object.keys(state.plugins).length !== 0 && (
          <>
            <WidgetProvider infoJson={infoJson}>
              <ThemeProvider
                theme={infoJson.Layout.ThemeDark ? darkTheme : lightTheme}
              >
                <GlobalTheme infoJson={infoJson} />
                <HelmetProvider>
                  <ReactSEOMetaTags
                    render={(el) => (
                      <Helmet>
                        {/* Chrome, Firefox OS and Opera  */}
                        <meta
                          name="theme-color"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {/* Windows Phone  */}

                        <meta
                          property="og:site_name"
                          content={infoJson.Stand.Nome}
                        />

                        <meta
                          name="msapplication-navbutton-color"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {/* iOS Safari  */}
                        <meta
                          name="apple-mobile-web-app-status-bar-style"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {state?.plugins?.PluginFacebook.existe && (
                          <meta
                            name="facebook-domain-verification"
                            content={
                              state?.plugins?.PluginFacebook
                                .facebookDomainVerification
                            }
                          />
                        )}

                        {state?.plugins?.AutoUncle?.existe && (
                          <script>
                            {`window.AuEmbed=window.AuEmbed || {
                      pushedUrls:[],pushUrl:function(url){AuEmbed.pushedUrls.push(url)}                    
                               };

                      window.AuEmbed.config = ${JSON.stringify(
                        state?.plugins?.AutoUncle?.config
                      )};
                      
                      document.head.querySelector("meta[name=viewport]")||console.warn(
                      'The AutoUncle Universal Script cannot detect any  tag. Make sure that the script is added after this tag!'
                      );var d={depId:AuEmbed.config.departmentId,device:window.matchMedia(
                      "only screen and (max-width: 760px)").matches?"mobile":"desktop",
                      pageType:AuEmbed.config.pageType,
                      url:AuEmbed.config.previewUrl||document.location.href},
                      s=document.createElement("script");s.async=!0,
                      s.src="https://www.autouncle.pt/pt/customers/"+encodeURIComponent(
                      AuEmbed.config.id)+"/website_script.js?d="+encodeURIComponent(
                      JSON.stringify(d)),s.id="au-cweb-script",
                      h=document.getElementsByTagName("head")[0],h.insertBefore(s,
                      h.lastChild);                 
                 
                      `}
                          </script>
                        )}

                        {metaTags.blogPostData ? (
                          <script type="application/ld+json">
                            {JSON.stringify(metaTags.blogPostData)}
                          </script>
                        ) : null}

                        {el}
                      </Helmet>
                    )}
                    website={metaTags.fullWebsiteData}
                    facebook={metaTags.facebookData}
                    twitter={metaTags.twitterData}
                  />
                </HelmetProvider>

                <GestorNeves infoJson={infoJson} />

                {state.allLista.length === 0 ? (
                  <>
                    <Siteholder infoJson={infoJson} />
                  </>
                ) : (
                  <>
                    <Rotas
                      setMetaTags={setMetaTags}
                      infoJson={infoJson}
                      selectedItems={selectedCarsComparar}
                      addToCompare={addToCompare}
                      removeFromCompare={removeFromCompare}
                      allinfoText={allinfoText}
                      selectedCarsComparar={selectedCarsComparar}
                      removeFromCompareAll={removeFromCompareAll}
                      setTextoModal={setTextoModal}
                      textoModal={textoModal}
                      setShowModalComparison={setShowModalComparison}
                      showModalComparison={showModalComparison}
                    />
                  </>
                )}
                <Suspense fallback={<></>}>
                  {!state?.plugins?.OneTrust?.existe ? (
                    <CookieConsentir allinfoText={allinfoText} />
                  ) : null}
                </Suspense>
              </ThemeProvider>
            </WidgetProvider>
          </>
        )}
    </>
  );
}
